import { list, size } from 'public/styles';

const sizeHelper = (tag, name, type, media, defaultValue) =>
	list.indexOf(name) !== -1
		? media
			? type
				? size[tag][name][type][media]
				: size[tag][name][media]
			: type
				? size[tag][name][type]
				: size[tag][name]
		: size[defaultValue];

/**
 * Icons
 */
export const icon = (sizeName) => {
	return sizeHelper('icon', sizeName, null, 'mxl', 'full');
};
/* Media queries: */
export const iconML = (sizeName) => {
	return sizeHelper('icon', sizeName, null, 'ml', 'full');
};
export const iconMS = (sizeName) => {
	return sizeHelper('icon', sizeName, null, 'ms', 'full');
};
export const iconMXS = (sizeName) => {
	return sizeHelper('icon', sizeName, null, 'mxs', 'full');
};
/**
 * Avatars
 */
export const avatar = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'size', 'mxl', 'full');
};
/* Media queries: */
export const avatarML = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'size', 'ml', 'full');
};
export const avatarMS = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'size', 'ms', 'full');
};
export const avatarMXS = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'size', 'mxs', 'full');
};
export const avatarText = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'text', 'mxl', 'full');
};
/* Media queries: */
export const avatarTextML = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'text', 'ml', 'full');
};
export const avatarTextMS = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'text', 'ms', 'full');
};
export const avatarTextMXS = (sizeName) => {
	return sizeHelper('avatar', sizeName, 'text', 'mxs', 'full');
};
/**
 * Grid
 */
export const containerSize = (sizeName) => {
	return sizeHelper('container', sizeName, null, null, 'zero');
};
/**
 * Modal
 */
export const modalSize = (sizeName) => {
	return sizeHelper('modal', sizeName, null, null, 'full');
};

export const videoModalSize = (sizeName) => {
	return sizeHelper('videoModal', sizeName, null, null, 'full');
};

export default sizeHelper;
