import styled from 'styled-components';

const FormContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	gap: 32px;
`;

export default FormContainer;
