import { notifyError } from 'helpers/errorNotifier';

export const getQueryParams = (url) => {
	try {
		if (!url || !url.includes('?')) return {};
		const paramsString = url.split('?')[1];
		const params = paramsString
			.split('&')
			.map((keyValueParamString) => {
				const [key, value] = keyValueParamString.split('=').map(decodeURI);
				return { [key]: value };
			})
			.reduce((prev, curr) => ({ ...prev, ...curr }), {});
		return params;
	} catch (error) {
		notifyError(`Error trying to exectue getQueryParams: ${error}`, {
			metadata: {
				url,
			},
		});
		return {};
	}
};
