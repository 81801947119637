import { Body, Headline, FlexBox } from '@Backlot-Cars/archie';
import { Card, FirstColumn, Title, TwoColumn } from './styles';

export const FirstColumnText = ({ firstColumnTitle, firstColumnSubtitle }) => {
	if (firstColumnTitle || firstColumnSubtitle) {
		return (
			<>
				<Title>
					<Headline size="H1" color="secondary">
						{firstColumnTitle}
					</Headline>
				</Title>
				<Body>{firstColumnSubtitle}</Body>
			</>
		);
	}
	// default case for sign in, and password forgot/reset
	return (
		<>
			<Title>
				<Headline size="H1" color="secondary" bold>
					Wholesale.
				</Headline>
				<Headline size="H1" color="primary" bold>
					Done.
				</Headline>
			</Title>
			<Body size="largest">We reimagined how to buy and sell wholesale vehicles.</Body>
		</>
	);
};

const TwoColumnLayout = ({ firstColumnTitle, firstColumnSubtitle, SecondColumnComp, CardContent, noCardPadding }) => {
	return (
		<FlexBox justifyContent="center">
			<TwoColumn>
				<FirstColumn>
					<FirstColumnText firstColumnTitle={firstColumnTitle} firstColumnSubtitle={firstColumnSubtitle} />
				</FirstColumn>

				{SecondColumnComp || <Card noCardPadding={noCardPadding}>{CardContent}</Card>}
			</TwoColumn>
		</FlexBox>
	);
};

export default TwoColumnLayout;
