import { Body, FormControl, FormError, Headline, Input, Button } from '@Backlot-Cars/archie';
import { BlcLoader } from 'components/BlcLoader';
import FormContainer from 'components/FormContainer';
import { getQueryParams } from 'helpers/queryParams';
import { getPathFromURL } from 'helpers/url';
import { getUtmParamsString } from 'helpers/user/getUserFromRailsCookie';
import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import AuthFormActions from 'partials/shared/user_form/_auth_form_actions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserSessionService from 'services/navigation/userSession.service';
import { Grouper } from './styles';

const SectionLanding = (props) => {
	const { t } = useTranslation(['common', 'signin']);
	const [doLogin, setDoLogin] = useState(false);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const { passwordForgot, signUpUrl, refererUrl } = props;
	const errorMessage = t('common:error');
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	useEffect(() => {
		(async function login() {
			if (doLogin) {
				setLoading(true);
				setError('');

				const values = getValues();
				const { email, password } = values;

				try {
					const utmParams = getUtmParamsString(getQueryParams(refererUrl));
					const result = await UserSessionService.instance.signIn(email, password, utmParams);

					if (refererUrl) {
						location.replace(getPathFromURL(refererUrl, '/home'));
					} else {
						Router.push(getPathFromURL(result.headers.location || '/home', '/home'));
					}
				} catch (error) {
					setError(error?.error?.message || errorMessage);
				} finally {
					setLoading(false);
					setDoLogin(false);
				}
			}
		})();
	}, [doLogin, getValues, errorMessage, refererUrl]);

	const onSubmit = () => setDoLogin(true);

	const onKeyDown = (event) => {
		if (event.key === 'Enter') {
			onSubmit();
		}
	};

	return (
		<>
			<FormContainer>
				<Grouper gap={16}>
					<Headline size="H3">{t('signin:landing.content.title')}</Headline>
					<Body>
						{t('signin:landing.content.actions.signUp')}{' '}
						<Button variant="link" href={signUpUrl}>
							{t('signin:landing.content.actions.requestInvite')}
						</Button>
					</Body>
				</Grouper>

				<Grouper gap={24}>
					<FormControl label={t('signin:landing.content.fields.emailUsername')} formErrors={errors} required>
						<Input
							maxLength="255"
							id="email"
							{...register('email', {
								required: t('common:emailUsernameRequired'),
							})}
							onKeyDown={onKeyDown}
						/>
					</FormControl>
					<FormControl label={t('signin:landing.content.fields.password')} formErrors={errors} required>
						<Input
							type="password"
							autocomplete="off"
							maxLength="255"
							id="password"
							{...register('password', {
								required: t('common:passwordRequired'),
							})}
							onKeyDown={onKeyDown}
						/>
					</FormControl>
				</Grouper>

				<>
					{!!loading && <BlcLoader size="larger" />}
					{!!error && <FormError>{error}</FormError>}
				</>

				<AuthFormActions
					linkUrl={passwordForgot}
					linkText={t('signin:landing.content.fields.forgot')}
					submitText={t('signin:landing.content.actions.logIn')}
					onClickSubmit={handleSubmit(onSubmit)}
				/>
			</FormContainer>
		</>
	);
};

export default SectionLanding;
