import { Button } from '@Backlot-Cars/archie';
import PropTypes from 'prop-types';
import { AuthCTAWrapper } from './styles';

const AuthFormActions = (props) => {
	const renderLink = () => {
		return props.renderLink ?
				<Button variant="link" href={props.linkUrl}>
					{props.linkText}
				</Button>
			:	null;
	};

	return (
		<AuthCTAWrapper>
			{renderLink()}

			<Button type={props.onClickSubmit ? 'button' : 'submit'} onClick={props.onClickSubmit}>
				{props.submitText}
			</Button>
		</AuthCTAWrapper>
	);
};

AuthFormActions.propTypes = {
	renderLink: PropTypes.bool,
	linkText: PropTypes.string,
	linkUrl: PropTypes.string,
	submitText: PropTypes.string,
	// when passed it will act as button if not, as a form submit
	onClickSubmit: PropTypes.func,
};

AuthFormActions.defaultProps = {
	renderLink: true,
	linkText: null,
	linkUrl: null,
	submitText: 'Submit',
};

export default AuthFormActions;
