import { BlcBox } from '@Backlot-Cars/archie';
import BlcLayoutBase from 'components/BlcLayout/BlcLayout.base';
import { Wrapper } from './style';

const BlcLoader = (props) => {
	return (
		<Wrapper size={props.size} marginLeft={props.marginLeft}>
			<div className={'lds-ring'}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Wrapper>
	);
};

export const PageLoader = () => (
	<BlcLayoutBase robotsMeta>
		<BlcBox height="100vh" display="flex" justifyContent="center" alignItems="center">
			<BlcLoader />
		</BlcBox>
	</BlcLayoutBase>
);

BlcLoader.defaultProps = {
	size: 'normal',
};

export default BlcLoader;
