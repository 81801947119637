import styled from 'styled-components';
import { icon, iconML, iconMS, iconMXS } from 'helpers/size';
import { media } from 'public/styles';

const normalSize = (size) => icon(size);
const largerSize = (size) => iconML(size);
const smallerSize = (size) => iconMS(size);
const smallestSize = (size) => iconMXS(size);

export const Wrapper = styled.div`
.lds-ring {
  display: flex;
  justify-content: center;
  position: relative;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 4px solid #969696;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #969696 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  height: ${props => normalSize(props.size)};
  margin-left: ${props => props.marginLeft || ''};
}

.lds-ring div {
  width: ${props => normalSize(props.size)};
  height: ${props => normalSize(props.size)};
}

@media ${media.larger} {
  .lds-ring {
    height: ${props => largerSize(props.size)};
  }

  .lds-ring div {
    width: ${props => largerSize(props.size)};
    height: ${props => largerSize(props.size)};
  }
}
@media ${media.smaller} {
  .lds-ring {
    height: ${props => smallerSize(props.size)};
  }

  .lds-ring div {
    width: ${props => smallerSize(props.size)};
    height: ${props => smallerSize(props.size)};
  }
}
@media ${media.smallest} {
  .lds-ring {
    height: ${props => smallestSize(props.size)};
  }

  .lds-ring div {
    width: ${props => smallestSize(props.size)};
    height: ${props => smallestSize(props.size)};
  }
}
`;
