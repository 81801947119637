import styled from 'styled-components';
import { media } from 'public/styles';


export const AuthCTAWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media ${media.smallest} {
		flex-direction: column-reverse;
		gap: 16px;

		button {
			width: 100%;
		}

		a {
			font-size: 16px;
		}
	}
`;
